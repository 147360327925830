body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.colorGreen {
  background-color: green !important;
}

#table {
  border-collapse: collapse;
  border: 2px solid rgb(200, 200, 200);
  letter-spacing: 1px;
  font-family: sans-serif;
  font-size: .8rem;
  margin-left: auto; 
  margin-right: auto;
}

.td {
  border: 1px solid rgb(190, 190, 190);
  padding: 10px;
  text-align: center;
}

.th {
    border: 1px solid rgb(190, 190, 190);
    padding: 10px;
}

.tr:nth-child(even) {
  background-color: #eee;
}

.th[scope="col"] {
  background-color: #696969;
  color: #fff;
  text-align: center;
}

.th[scope="row"] {
  background-color: #d7d9f2;
}

.backOrBeginButton{
  display: inline;

}
canvas {
  width: 99vw;
  height:70vh;
  border: 1px solid black;
}

.invoiceSummaryButtons {
  margin-top: 1rem;
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: min(100%, 360px);
}

.invoiceSummaryButtons > * {
  margin: 0.25rem;
  max-width: 100%;
}

.invoiceSummaryButtons > .dropdown {
  grid-column: span 2;
}

.invoiceSummaryButtons > .dropdown > button {
  width: 100%;
}

.invoiceSummaryButtons .dropdown-toggle {
  white-space: normal !important;
}

@media screen and (max-width: 425px) {
  .invoiceSummaryButtons {
    grid-template-columns: 1fr;
  }

  .invoiceSummaryButtons > .dropdown {
    grid-column: span 1;
  }
}

.app-top-btn-container {
  display: flex;
  gap: 1rem;
}
