@media (prefers-reduced-motion: no-preference) {
    .AnimInstallText {
    animation: Install-text-anim infinite 2s linear;
    }
    }
@keyframes Install-text-anim{
    from {
    opacity: 0;
    }
    50% {
    opacity: 1;
    }
    to {
    opacity: 0;
    }
}
